const workbooks = [
    {
        id: 'default-1',
        name: 'marketsOverview',
        profile: {
            isActive: true
        },
        activeWorksheetId: 'default-1',
        worksheets: [
            {
                id: 'default-1',
                name: 'KPB Market Dashboard',
                isTemplate: false,
                profile: {
                    isVisible: true,
                    editable: false,
                    reorderEnabled: false,
                    showCloseIcon: false,
                    componentDragable: false,
                    layoutConfig: [
                        {
                            type: 'column',
                            content: [
                                {
                                    type: 'row',
                                    height: 70,
                                    content: [
                                        {
                                            type: 'column',
                                            width: 60,
                                            content: [
                                                {
                                                    type: 'stack',
                                                    height: 40,
                                                    content: [
                                                        {
                                                            type: 'component',
                                                            componentName:
                                                                'mwc-markets-watchlist-pre-market-futures'
                                                        },
                                                        {
                                                            type: 'component',
                                                            componentName:
                                                                'mwc-markets-watchlist-kpb-market'
                                                        },
                                                        {
                                                            type: 'component',
                                                            componentName:
                                                                'mwc-markets-watchlist-morningstar-sector'
                                                        },
                                                        {
                                                            type: 'component',
                                                            componentName:
                                                                'mwc-markets-commodity'
                                                        },
                                                        {
                                                            type: 'component',
                                                            componentName:
                                                                'mwc-markets-watchlist-morningstar-government'
                                                        }
                                                    ]
                                                },
                                                {
                                                    type: 'stack',
                                                    height: 60,
                                                    content: [
                                                        {
                                                            type: 'component',
                                                            componentName:
                                                                'mwc-markets-gmb'
                                                        },
                                                        {
                                                            type: 'component',
                                                            componentName:
                                                                'mwc-markets-sector-heatmap'
                                                        },
                                                        {
                                                            type: 'component',
                                                            componentName:
                                                                'mwc-markets-barometer'
                                                        },
                                                        {
                                                            type: 'component',
                                                            componentName:
                                                                'mwc-markets-treasury',
                                                            componentState: {
                                                                yields:
                                                                    'lastYield,yield6MonthAgo,yield1YearAgo,yield3YearAgo,yield5YearAgo'
                                                            }
                                                        },
                                                        {
                                                            type: 'component',
                                                            componentName:
                                                                'mwc-markets-watchlist-kpb-market'
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            type: 'stack',
                                            width: 40,
                                            content: [
                                                {
                                                    type: 'component',
                                                    componentName:
                                                        'mwc-markets-model-portfolio'
                                                },
                                                {
                                                    type: 'component',
                                                    componentName:
                                                        'mwc-markets-watchlist-core-equity'
                                                },
                                                {
                                                    type: 'component',
                                                    componentName:
                                                        'mwc-markets-watchlist-high-dividend'
                                                },
                                                {
                                                    type: 'component',
                                                    componentName:
                                                        'mwc-markets-watchlist-sea-equity'
                                                },
                                                {
                                                    type: 'component',
                                                    componentName:
                                                        'mwc-markets-watchlist-us-equity'
                                                },
                                                {
                                                    type: 'component',
                                                    componentName:
                                                        'mwc-markets-watchlist-intl-equity'
                                                },
                                                {
                                                    type: 'component',
                                                    componentName:
                                                        'mwc-markets-watchlist-fixed-income'
                                                },
                                                {
                                                    type: 'component',
                                                    componentName:
                                                        'mwc-markets-watchlist-alternatives'
                                                },
                                                {
                                                    type: 'component',
                                                    componentName:
                                                        'mwc-markets-watchlist-real-assets'
                                                },
                                                {
                                                    type: 'component',
                                                    componentName:
                                                        'mwc-markets-watchlist-kpb-market'
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    type: 'row',
                                    height: 30,
                                    content: [
                                        {
                                            type: 'component',
                                            width: 60,
                                            componentName:
                                                'markets-components-svgchart',
                                            componentState: {
                                                intervalType: 'FiveDay',
                                                frequency: 5
                                            }
                                        },
                                        {
                                            type: 'component',
                                            componentName:
                                                'mwc-markets-news-investment'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            },
            {
                id: 'default-2',
                name: 'FICC',
                isTemplate: true,
                profile: {
                    isVisible: true,
                    editable: true,
                    reorderEnabled: true,
                    showCloseIcon: true,
                    componentDragable: true,
                    layoutConfig: [
                        {
                            type: 'row',
                            content: [
                                {
                                    type: 'column',

                                    content: [
                                        {
                                            type: 'component',
                                            height: 55,
                                            componentName:
                                                'mwc-markets-treasury',
                                            componentState: {
                                                yields:
                                                    'lastYield,yield6MonthAgo,yield1YearAgo,yield3YearAgo,yield5YearAgo'
                                            }
                                        },
                                        {
                                            type: 'component',
                                            componentName:
                                                'mwc-markets-commodity'
                                        }
                                    ]
                                },
                                {
                                    type: 'column',
                                    content: [
                                        {
                                            type: 'component',
                                            componentName:
                                                'mwc-markets-treasury',
                                            height: 55,
                                            componentState: {
                                                viewType: 'tableView',
                                                yields:
                                                    'lastYield,yield6MonthAgo,yield1YearAgo,yield3YearAgo,yield5YearAgo'
                                            }
                                        },
                                        {
                                            type: 'component',
                                            componentName: 'mwc-markets-forex'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            },
            {
                id: 'default-3',
                name: 'Stock Report',
                isTemplate: true,
                profile: {
                    isVisible: true,
                    editable: true,
                    reorderEnabled: true,
                    showCloseIcon: true,
                    componentDragable: true,
                    layoutConfig: [
                        {
                            type: 'row',
                            content: [
                                {
                                    type: 'column',
                                    content: [
                                        {
                                            type: 'component',
                                            componentName: 'mwc-markets-quote'
                                        },
                                        {
                                            type: 'component',
                                            height: 30,
                                            componentName:
                                                'markets-components-svgchart',
                                            componentState: {
                                                intervalType: 'OneMonth'
                                            }
                                        },
                                        {
                                            type: 'component',
                                            componentName:
                                                'mwc-markets-news-investment'
                                        }
                                    ]
                                },
                                {
                                    type: 'column',
                                    content: [
                                        {
                                            type: 'component',
                                            componentName:
                                                'mwc-markets-profile-report'
                                        },
                                        {
                                            type: 'component',
                                            componentName: 'mwc-markets-report'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            },
            {
                id: 'default-4',
                name: 'Fund Report',
                isTemplate: true,
                profile: {
                    isVisible: true,
                    editable: true,
                    reorderEnabled: true,
                    showCloseIcon: true,
                    componentDragable: true,
                    layoutConfig: [
                        {
                            type: 'row',
                            content: [
                                {
                                    type: 'component',
                                    componentName: 'mwc-markets-report'
                                }
                            ]
                        }
                    ]
                }
            },
            {
                id: 'default-5',
                name: 'News',
                isTemplate: true,
                profile: {
                    isVisible: true,
                    editable: true,
                    reorderEnabled: true,
                    showCloseIcon: true,
                    componentDragable: true,
                    layoutConfig: [
                        {
                            type: 'row',
                            content: [
                                {
                                    type: 'component',
                                    componentName: 'mwc-markets-news-investment'
                                },
                                {
                                    type: 'component',
                                    componentName: 'mwc-markets-news-us-markets'
                                }
                            ]
                        }
                    ]
                }
            },
            {
                id: 'default-6',
                name: 'Chart',
                isTemplate: true,
                profile: {
                    isVisible: true,
                    editable: true,
                    reorderEnabled: true,
                    showCloseIcon: true,
                    componentDragable: true,
                    layoutConfig: [
                        {
                            type: 'row',
                            content: [
                                {
                                    type: 'component',
                                    componentName:
                                        'markets-components-svgchart',
                                    componentState: {
                                        intervalType: 'FiveYear',
                                        compareTickers: ['FEUSA00001'],
                                        indicators: [
                                            {
                                                name: 'SMA',
                                                parameters: [50, 200]
                                            },
                                            {
                                                name: 'BBands',
                                                parameters: [20, 2]
                                            }
                                        ]
                                    }
                                },
                                {
                                    type: 'component',
                                    componentName:
                                        'markets-components-svgchart',
                                    componentState: {
                                        intervalType: 'FiveYear',
                                        indicators: [
                                            {
                                                name: 'SMA',
                                                parameters: [50, 200]
                                            },
                                            {
                                                name: 'BBands',
                                                parameters: [20, 2]
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    ]
                }
            },
            {
                id: 'default-7',
                name: 'Options',
                isTemplate: true,
                profile: {
                    isVisible: true,
                    editable: true,
                    reorderEnabled: true,
                    showCloseIcon: true,
                    componentDragable: true,
                    layoutConfig: [
                        {
                            type: 'column',
                            content: [
                                {
                                    type: 'component',
                                    componentName: 'mwc-markets-options',
                                    height: 60,
                                    componentState: {
                                        strikeRange: 6
                                    }
                                },
                                {
                                    type: 'component',
                                    componentName: 'markets-components-svgchart'
                                }
                            ]
                        }
                    ]
                }
            }
        ]
    }
];

export default workbooks;
