const globalSettings = {
    region: 'US',
    sessionExpiration: 36000, // In seconds, 60*60*10 which means 10 h
    theme: 'black',
    dataType: 'pull',
    autoSave: true,
    autoSaveInterval: 5,
    showVideo: false,
    notificationSound: 'on',
    notificationType: 'screen&email',
    functions: 'horizon.multi_themes,horizon.excel_rtd',
    userGuide:
        'https://morningstardirect.morningstar.com/clientcomm/KeyBankMM.PDF'
};

export default globalSettings;
